import React, { useEffect, useRef, useState } from 'react'
import { motion, useInView, useAnimation } from 'framer-motion'

const Hero = ({ top }) => {

  const [screenSize, setScreenSize] = useState(window.innerWidth < 905);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 905);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visable');
    }
  }, [isInView])
    
  return (
    <div ref={top} className={`${screenSize ? 'hero-grid min-h-screen p-8 pb-12' : 'h-70vh hero-grid-large align-text-bottom p-12'}  bg-gray-700 scroll-mt-24`}>
        <motion.div
        variants={{
            hidden: { opacity: 0, y: -75},
            visable: {opacity: 1, y: 0}
        }}
        initial='hidden'
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25}}
        ref={ref}
        className={`${screenSize ? 'text-5xl' : 'relative text-6xl md:text-5xl'} text-gray-100`}>
            <div className={`${screenSize ? '' : 'absolute top-1/2 -translate-y-1/2 pr-8'}`}>
                I'm Luke and welcome to my website! I am a freelance web-developer that works with both businesses and individuals.
                {screenSize ? '' : <motion.div 
                    variants={{
                        hidden: { opacity: 0, x: -75},
                        visable: {opacity: 1, x: 0}
                    }}
                    initial='hidden'
                    animate={mainControls}
                    transition={{ duration: 0.5, delay: 0.25}}
                    ref={ref} 
                    className='grid grid-rows-2 mt-2'>
                        <div className='text-gray-100 text-xl'>
                            Email: <span className='text-cyan-400 font-semibold'>info@lukesoutherton.com</span>
                        </div>
                        <div className='text-gray-100 text-xl'>
                            Phone: <span className='text-cyan-400 font-semibold'>07399525381</span>
                        </div>
                    </motion.div>}
            </div>
        </motion.div>
        {screenSize ? <motion.div 
        variants={{
            hidden: { opacity: 0, x: -75},
            visable: {opacity: 1, x: 0}
        }}
        initial='hidden'
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25}}
        ref={ref} 
        className='grid grid-rows-2 mt-2'>
            <div className='text-gray-100 text-xl'>
                Email: <span className='text-cyan-400 font-semibold'>info@lukesoutherton.com</span>
            </div>
            <div className='text-gray-100 text-xl'>
                Phone: <span className='text-cyan-400 font-semibold'>07399525381</span>
            </div>
        </motion.div> : ''}
        <motion.div
        variants={{
            hidden: { opacity: 0, y: 75},
            visable: {opacity: 1, y: 0}
        }}
        initial='hidden'
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.25}}
        ref={ref}
        className={`${screenSize ? '' : 'text-xl row-span-2 relative'} text-gray-100 mt-6`}>
            <div className={`${screenSize ? '' : 'absolute bottom-0'}`}>
                I am a both a website designer and developer who primarily works with ReactJS to create professional looking websites that will help you to catch the eye of potential customers or clientel.
                <br></br>
                <br></br>
                My websites are especially tailored to the requirements that are outlined by my clients and I pride myself on reaching an end product that is inline with their business goals.
                <br></br>
                <br></br>
                If you are interested in getting in touch, then please send me an email or feel free to give me a call!
            </div>
        </motion.div>
    </div>
  )
}

export default Hero