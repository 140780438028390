import React, { useState, useEffect } from 'react'
import ListItem from './ListItem'

const WhatToList = ({ process }) => {

  const [screenSize, setScreenSize] = useState(window.innerWidth < 1100);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 1100);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  return (
    <div ref={process} className={`${screenSize ? 'w-full text-4xl' : 'w-2/3 m-auto text-5xl pt-16 pb-20'} h-fit pr-8 pl-8 mt-8 mb-8 scroll-mt-28`}>
      <h1 className='text-gray-700 font-semibold text-center'>What to Expect</h1>
      <ListItem direction={75} text='A process especially crafted to ensure that you are satisfied with the end product.' img='./pictures/monitor.png' />
      <ListItem direction={-75} text='An especially designed product that is angled towards the correct clientel.' img='./pictures/people.png' />
      <ListItem direction={75} text='A website that looks truely as you envision with plenty of opportunities to change aspects to fit your specification.' img='./pictures/open-book.png' />
      <ListItem direction={-75} text='Guidance on how to host your website and dealing with future changes.' img='./pictures/calendar.png' />
    </div>
  )
}

export default WhatToList