import React, { useState, useEffect } from 'react'
import Question from './Question'
import Question2 from './Question2';

const FAQs = ({ faq }) => {

  const [screenSize, setScreenSize] = useState(window.innerWidth < 1100);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 1100);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  return (
    <div ref={faq} className={`${screenSize ? 'pb-10' : ' pb-20 pt-16'} bg-gray-700 h-fit w-full scroll-mt-20`}>
        <div className={`${screenSize ? 'text-4xl' : 'text-5xl'} text-gray-100 text-center pt-10 font-semibold`}>
            FAQs
        </div>
        <Question2
            question='What do I need to get started?'
            answer='Ideally, you will already have any pictures or logos to hand and have an idea of the colour scheme. Everything will be covered in an introductary call in which we will discuss this in more depth.'
        />
        <Question2
            question='What prices do you charge for your services?'
            answer='The price will depend on the size of the project. Prices start at £400 and range up to £1100. Please get in touch for more details.'
        />
        <Question2
            question='Could you edit my pre-existing website for me?'
            answer='I only work on projects that I can complete from start to finish. Having a full understanding of the website will allow me to produce the best product possible.'
        />
        <Question2
            question='How long will it take to create a fully finished website?'
            answer='I aim to complete the first revision within 2 weeks of the starter form being completed. The end product will be finished within roughly a month. However, this will vary depending on the website size.'
        />
    </div>
  )
}

export default FAQs