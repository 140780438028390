import React, { useEffect, useRef, useState } from 'react'
import { motion, useInView, useAnimation } from 'framer-motion'
import { Link } from 'react-router-dom';

const PrevProject = ({ direction, name, image, link }) => {

  const [screenSize, setScreenSize] = useState(window.innerWidth < 1100);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 1100);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visable');
    }
  }, [isInView])

  return (
    <motion.div 
    variants={{
      hidden: { opacity: 0, x: direction},
      visable: {opacity: 1, x: 0}
    }}
    initial='hidden'
    animate={mainControls}
    transition={{ duration: 0.5, delay: 0.25}}
    ref={ref}
    className={`${screenSize ? 'w-80 hover:w-96' : 'w-project-large w-project-large-hover mt-20 mb-20'} cursor-pointer mr-4 ml-4 pb-10 text-center mb-8 inline-block duration-200`}>
      <a href={link} target='_blank'>
        <img className='rounded w-full shadow-lg shadow-gray-400 m-auto hover:shadow-xl hover:shadow-gray-500' src={image} />
      </a>
      <div className='text-2xl text-gray-700 mt-4 font-semibold'>
        {name}
      </div>
    </motion.div>
  )
}

export default PrevProject