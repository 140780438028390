import React, { useState, useEffect } from 'react'

const Question2 = ({ question, answer }) => {

  const [hidden, setHidden] = useState(true);

  const [screenSize, setScreenSize] = useState(window.innerWidth < 1100);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 1100);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  return (
    <div className={`${!hidden ? 'open-grid' : ''} ${screenSize ? 'w-5/6 text-2xl' : 'w-1/2 text-4xl'} m-auto h-fit mt-6 question-grid cursor-pointer`}>
      <div onClick={() => setHidden(prev => !prev)} className={`${screenSize ? '' : ''} border-b-2 border-cyan-400 mb-2 text-gray-100 p-2`}>
        {question}
      </div>
      <div className={`${!hidden ? '' : ''} ${screenSize ? 'text-xl' : 'text-2xl'} text-gray-100 mt- overflow-hidden`}>
        {answer}
      </div>
    </div>
  )
}

export default Question2