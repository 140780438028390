import React, { useState, useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from 'framer-motion'

const ListItem = ({ text, img, direction }) => {

  const [screenSize, setScreenSize] = useState(window.innerWidth < 1100);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 1100);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visable');
    }
  }, [isInView])

  return (
    <motion.div 
    variants={{
      hidden: { opacity: 0, x: direction},
      visable: {opacity: 1, x: 0}
    }}
    initial='hidden'
    animate={mainControls}
    transition={{ duration: 0.5, delay: 0.25}}
    ref={ref}
    className='grid grid-cols-4 w-full mt-8 items-center'>
      <img className='w-1/2 m-auto' src={img} />
      <div className={`col-span-3 ${screenSize ? 'text-lg' : 'text-3xl'} text-gray-700 text-left`}>
        {text}
      </div>
    </motion.div>
  )
}

export default ListItem