import React, { useState, useEffect } from 'react'

const Footer = () => {

  const [screenSize, setScreenSize] = useState(window.innerWidth < 1100);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 1100);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  return (
    <div className={`${screenSize ? 'text-xl pb-10 pt-6' : 'text-2xl pb-16 pt-12'} bg-gray-700 -mt-0.5 text-cyan-400 text-center font-semibold relative`}>
        <div className=''>
            Email: info@lukesoutherton.com
            <br></br>
            Phone: 07399525381
        </div>
    </div>
  )
}

export default Footer