import React, { useState, useEffect } from 'react'

const Header2 = ({ top, contact, about, projects, setMenu, menu }) => {

  const [screenSize, setScreenSize] = useState(window.innerWidth < 700);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 700);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  return (
    <div className={`sticky top-0 ${screenSize ? 'p-2' : 'p-4'} w-full h-24 bg-gray-700 items-center z-10`}>
        <div onClick={() => {
            top.current?.scrollIntoView({
            behavior: 'smooth'
            })
        }} className='text-gray-100 text-5xl ml-2 inline-block mt-2'>
            <div className='w-fit hover:text-cyan-400 cursor-pointer'>
                LS
            </div>
        </div>
        {screenSize ?
            <div onClick={() => setMenu(prev => !prev)} className={`${menu ? 'menu-button-clicked' : ''} menu-button overflow-hidden inline-block ml-2 mr-1 float-right bg-transparent mt-2`}>
                <svg stroke='currentColor' fill='none' className={`${menu ? '-translate-y-2 translate-x-1.5 rotate-45' : ''} duration-1000`} viewBox='-10 -10 110 110' width='50'>
                    <path className='line' stroke-width='10' stroke-linecap='round' stroke-linejoin='round' d='m 20 40 h 60 a 1 1 0 0 1 0 20 h -60 a 1 1 0 0 1 0 -40 h 31 v 70'></path>
                </svg>
            </div> :
            <div className={`pr-4 inline-block float-right mt-3`}>
                <div onClick={() => {
                    projects.current?.scrollIntoView({
                        behavior: 'smooth'
                    })
                }} className='text-xl text-gray-100 inline m-auto hover:text-cyan-400 cursor-pointer mr-4 ml-4'>
                    PROJECTS
                </div>
                <div onClick={() => {
                    about.current?.scrollIntoView({
                        behavior: 'smooth'
                    })
                }} className='text-xl text-gray-100 inline m-auto hover:text-cyan-400 cursor-pointer mr-4 ml-4'>
                    ABOUT
                </div>
                <div onClick={() => {
                    contact.current?.scrollIntoView({
                        behavior: 'smooth'
                    })
                }} className={`${screenSize ? 'ml-0.5' : 'ml-2'} text-xl text-gray-700 bg-cyan-400 pt-2 pb-2 p-2 text-center inline-block rounded cursor-pointer hover:bg-cyan-500`}>
                    CONTACT
                </div>
            </div>}
    </div>
  )
}

export default Header2