import React, { useState, useEffect } from 'react'
import PrevProject from './PrevProject'

const PreviousWork = ({ projects }) => {

  const [screenSize, setScreenSize] = useState(window.innerWidth < 1100);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 1100);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  return (
    <div ref={projects} className='bg-gray-100 relative scroll-mt-20'>
      <div class="gray-wave t-0.5">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
      </div>
      <div className={`${screenSize ? 'pt-20' : 'pt-32 pb-20'} text-center`}>
        <PrevProject direction={-75} name="isha" image='./pictures/isha.png' link='https://ishas-blog.web.app/' />
        <PrevProject direction={-75} name="YourGym" image='./pictures/yourgym.png' link='https://yourgym-f4b4e.web.app/' />
        <PrevProject direction={75} name="The Columbian Coffee Shop" image='./pictures/coffee-shop.png' link='https://coffee-shop-41689.web.app/' />
      </div>
    </div>
  )
}

export default PreviousWork