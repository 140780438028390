import React, { useState, useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from 'framer-motion'

const AboutMe = ({ about }) => {

  const [screenSize, setScreenSize] = useState(window.innerWidth < 1100);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 1100);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start('visable');
      slideControls.start('visable');
    }
  }, [isInView])

  return (
    <div ref={about} className={`${screenSize ? 'h-fit pb-10' : 'h-fit pb-20'} bg-gray-700 w-full scroll-mt-20`}>
        <div className={`${screenSize ? 'text-4xl' : 'text-5xl'} text-gray-100 text-center pt-10 font-semibold mb-4`}>
            About<span className='text-cyan-400'> Me</span>
        </div>
        <div className={`${screenSize ? '' : 'grid grid-cols-2 items-center'}`}>
          <div className='relative'>
            <motion.div
            variants={{
              hidden: { opacity: 0, y: 75},
              visable: {opacity: 1, y: 0}
            }}
            initial='hidden'
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.25}}
            ref={ref}
            className={`${screenSize ? 'pb-6 text-xl pr-10 pl-10' : 'pl-16 m-auto text-3xl pr-6'} text-gray-100 pt-4`}>
                I am a web-developer based in Nottingham who specialises in <span className='text-cyan-400 font-semibold'>JavaScript</span> to create bespoke websites to fit the needs of my clients. Whilst I am based in the Midlands, I work with customers across the whole of the UK.
                <br></br>
                <br></br>
                When building a site I am sure to consider the look and feel but also the targetted demographic, the speed, scalability and much more.
            </motion.div>
            <motion.div
            variants={{
              hidden: { left: 0 },
              visable: { left: '100%' }
            }}
            initial='hidden'
            animate={slideControls}
            transition={{ duration: 0.5, ease: 'easeIn'}}
            className='absolute top-4 bottom-0 left-0 right-0 bg-cyan-400 z-5'
            ></motion.div>
          </div>
          <img className={`${screenSize ? 'w-3/4' : 'w-3/4'} m-auto rounded-xl shadow-lg shadow-cyan-100`} src='./pictures/computer.jpg' />
        </div>

    </div>
  )
}

export default AboutMe