import './App.css';
import AboutMe from './Components/AboutMe';
import Contact from './Components/Contact';
import FAQs from './Components/FAQs';
import Footer from './Components/Footer';
import Hero from './Components/Hero';
import PreviousWork from './Components/PreviousWork';
import WhatToList from './Components/WhatToList';
import Header2 from './Components/Header2';
import { useRef, useState } from 'react';
import Menu from './Components/Menu';

function App() {

  const contactRef = useRef();
  const aboutRef = useRef();
  const projectsRef = useRef();
  const topRef = useRef();
  const processRef = useRef();
  const faqRef = useRef();

  const [menu, setMenu] = useState(false);

  return (
    <div className='overflow-x-hidden'>
      <Header2 top={topRef} contact={contactRef} about={aboutRef} projects={projectsRef} menu={menu} setMenu={setMenu} />
      <Menu menu={menu} setMenu={setMenu} about={aboutRef} projects={projectsRef} home={topRef} contact={contactRef} process={processRef} faq={faqRef} />
      <Hero top={topRef} />
      <PreviousWork projects={projectsRef} />
      <AboutMe about={aboutRef} />
      <WhatToList process={processRef} />
      <FAQs faq={faqRef} />
      <Contact contact={contactRef} />
      <Footer />
    </div>
  );
}

export default App;