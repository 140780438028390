import React, { useEffect, useRef, useState } from 'react'
import { motion, useInView, useAnimation, AnimatePresence } from 'framer-motion'

const Menu = ({ setMenu, menu, about, contact, home, faq, process, projects }) => {

  const ref = useRef(null);

  const mainControls = useAnimation();

  useEffect(() => {
    if (menu) {
      mainControls.start('visable');
    }
  }, [menu])

  const scroll = (place) => {
    place.current?.scrollIntoView({
    behavior: 'smooth'
    })
    setMenu(prev => !prev)
  }

  const [screenSize, setScreenSize] = useState(window.innerWidth < 1100);
  
  const updateView = () => {
    setScreenSize(window.innerWidth < 1100);
  }

  useEffect(() => {
    window.addEventListener('resize', updateView);
    return () => window.removeEventListener('resize', updateView);
  });

  return (
    <>
        <AnimatePresence>
            {menu ? <motion.div
                variants={{
                    hidden: { opacity: 0, x: 75},
                    visable: {opacity: 1, x: 0}
                }}
                initial='hidden'
                animate={mainControls}
                exit={{ opacity: 0, x: 75}}
                transition={{ duration: 0.5, delay: 0.2}}
                ref={ref}
                className={`${screenSize ? 'w-7/12' : 'w-1/4'} h-full fixed top-14 right-0 z-50 bg-gray-700`}>
                    <div className='w-full mb-4 m-auto'></div>
                    <div onClick={() => scroll(home)} className='text-gray-100 text-3xl italic pl-6 hover:text-cyan-400 cursor-pointer duration-300'>
                        Home
                    </div>
                    <div onClick={() => scroll(projects)} className='text-gray-100 text-3xl italic pl-6 mt-4 hover:text-cyan-400 cursor-pointer duration-300'>
                        Projects
                    </div>
                    <div onClick={() => scroll(about)} className='text-gray-100 text-3xl pl-6 mt-4 italic hover:text-cyan-400 cursor-pointer duration-300'>
                        About
                    </div>
                    <div onClick={() => scroll(process)} className='text-gray-100 text-3xl pl-6 mt-4 italic hover:text-cyan-400 cursor-pointer duration-300'>
                        The Process
                    </div>
                    <div onClick={() => scroll(faq)} className='text-gray-100 text-3xl pl-6 mt-4 italic hover:text-cyan-400 cursor-pointer duration-300'>
                        FAQs
                    </div>
                    <div onClick={() => scroll(contact)} className='text-gray-100 text-3xl pl-6 mt-4 italic hover:text-cyan-400 cursor-pointer duration-300'>
                        Contact
                    </div>
            </motion.div> : ''}
        </AnimatePresence>
    </>
  )
}

export default Menu